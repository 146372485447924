import {RAKE_TYPE, ROOM_TYPE} from "../../dataset";
import React, {useMemo} from "react";
import styled from "styled-components";
import useScreenOrientation, {MEDIA_DESKTOP} from "../../hooks/useScreenOrientation";
import {useTranslation} from "react-i18next";
import {bigBlindToGameStyle} from "../../utils/ring";
import {parseDatetime} from "../../constants/moment";
import {calcLevel, calcPlayTime} from "../../utils/tournament";
import {useRecoilState} from "recoil";
import {AlwaysBBType, gameOptionState} from "../../recoil/GameOption";
import {PrintAlwaysBB} from "../PrintAlwaysBB";
import {chipToCurrency, UserCurrency, UserCurrencyMark} from "../../conversionChip";
import {Textfit} from "react-textfit";

const Wrapper = styled.div<{bright: boolean}>`
  display: flex;
  flex-direction: column;
  gap: 4px;
  margin-top: 4px;
  color: #FFF;
  font-size: 10px;
  font-weight: 500;
  opacity: 0.7;
  
  ${p => p.bright && `
    color: #181A1D;
  `};

  @media ${MEDIA_DESKTOP} {
    font-size: 12px;
    margin-left: 16px;
    margin-top: 0;
    justify-content: center;
  }
  .sb-bb {
    width: 147px;
    max-width: 147px;
    @media ${MEDIA_DESKTOP} {
      width: 240px;
      max-width: 240px;
    }
  }
`;

function StatusBoard(
  {
    roomType,
    SB,
    BB,
    ante,
    rakeType,
    rake,
    myRank,
    totalMember,
    straddle,
    timerText,
    blindUpTimerText,
    groupData,
    currentMember,
    isObserve,
  }: {
    roomType: ROOM_TYPE;
    SB: number;
    BB: number;
    ante: number;
    rakeType: RAKE_TYPE;
    rake: number;
    currentMember:number;
    myRank: number;
    totalMember: number;
    straddle: boolean;
    timerText: string;
    blindUpTimerText: string;
    groupData?:any;
    isObserve: boolean;
  }
) {
  const [setting, setSetting] = useRecoilState(gameOptionState);
  const {t} = useTranslation();
  const orientation = useScreenOrientation();

  const ringBetText = useMemo<JSX.Element[]>(() => {
    let chunks = [];

    if (SB > 0 && BB > 0) {
      if(roomType === ROOM_TYPE.TOURNAMENT){
        chunks.push(<>
          SB : {SB.toLocaleString()} /
          BB : {BB.toLocaleString()}
        </>);
      }else{
        if(setting.alwaysBB == AlwaysBBType.fiat){
          chunks.push(<>
            SB : {UserCurrencyMark()}{chipToCurrency(UserCurrency(), SB, 1000 ).toLocaleString() } /
            BB : {UserCurrencyMark()}{chipToCurrency(UserCurrency(), BB, 1000 ).toLocaleString()}
          </>);
        }else{
          chunks.push(<>
            SB : {SB.toLocaleString()} /
            BB : {BB.toLocaleString()}
          </>);
        }
      }
    }
    if (ante > 0) {
      chunks.push(<>{t('앤티')} {PrintAlwaysBB(roomType, setting.alwaysBB, ante, BB)}</>);
    }

    return chunks;
  }, [SB, BB, ante, setting]);

  const rakeText = useMemo<string>(() => {
    if (rakeType === RAKE_TYPE.HAND_RAKE) {
      return `${t('핸드레이크')} ${rake.toLocaleString()}`;
    } else if (rakeType === RAKE_TYPE.POT_RAKE) {
      return `${t('팟레이크')} ${rake}%`;
    }

    return '';
  }, [rakeType, rake]);

  const isBrightTable = useMemo<boolean>(() => {
    const gameStyle = bigBlindToGameStyle(BB);
    return gameStyle === 4 || gameStyle === 6;
  }, [BB]);

  const curLevel = useMemo<number>(()=>{
    if(roomType == ROOM_TYPE.RING) return 0;
    if(groupData == null) return 0;

    const startedAt = parseDatetime(groupData.startedAt);
    const {
      playTimeSeconds,
      restTimeSeconds
    } = groupData.timeStructure;
    const playTime = calcPlayTime(startedAt, playTimeSeconds, restTimeSeconds);
    return calcLevel(playTime, groupData.blindStructure);
  },[blindUpTimerText, groupData])

  return <Wrapper className="status-board" bright={roomType == ROOM_TYPE.TOURNAMENT ? false : isBrightTable}>
    {
      roomType == ROOM_TYPE.TOURNAMENT && timerText && <>
        {roomType == ROOM_TYPE.TOURNAMENT && <div>
          {t('현재 레벨 : {{curLevel}}',{curLevel : curLevel + 1})}
        </div>}
        <div>{timerText}</div>
        {blindUpTimerText ? <div>{blindUpTimerText}</div> : <></>}
        {
          ringBetText.length > 0 && (
            ringBetText.map((text, i) => <div className='sb-bb' key={i}>
              <Textfit mode="single" max={orientation === 'landscape' ? 12 : 10} throttle={0}>
                <div>{text}</div>
              </Textfit>
            </div>)
          )
        }
        {
          Boolean(myRank && totalMember) && (
            <div>{t('내 순위')} {myRank} / {currentMember} / {totalMember}</div>
          )
        }
      </>
    }
    {
      roomType == ROOM_TYPE.RING && <>
        {
          ringBetText.length > 0 && (
            ringBetText.map((text, i) =>
              <div className='sb-bb' key={i}>
                <Textfit mode="single" max={orientation === 'landscape' ? 12 : 10} throttle={0}>
                  <div>{text}</div>
                </Textfit>
              </div>
              )
          )
        }
        {
          // (orientation !== 'portrait' && rakeText.length > 0) && (
          //   <div>{rakeText}</div>
          // )
        }
        {
          Boolean(straddle) && (
            <div>{t('스트래들')} {t('있음')}</div>
          )
        }
      </>
    }
  </Wrapper>;
}

export default StatusBoard;
