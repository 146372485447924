import {gameOptionState} from "../recoil/GameOption";

export const Sounds = {
  BGM_LIST: 'bgm_list.mp3',
  BGM_WAIT: 'bgm_wait.mp3',
  BGM_TOURNAMENT: 'bgm_tournament.mp3?v=1',
  EMOJI01: 'emoji01.wav',
  EMOJI02: 'emoji02.wav',
  EMOJI03: 'emoji03.wav',
  EMOJI04: 'emoji04.wav',
  EMOJI05: 'emoji05.wav',
  EMOJI06: 'emoji06.wav',
  EMOJI07: 'emoji07.wav',
  EMOJI08: 'emoji08.wav',
  EMOJI09: 'emoji09.wav',
  EMOJI10: 'emoji10.wav',
  EMOJI11: 'emoji11.wav',
  EMOJI12: 'emoji12.wav',
  EMOJI13: 'emoji13.wav',
  EMOJI14: 'emoji14.wav',
  EMOJI15: 'emoji15.wav',
  EMOJI16: 'emoji16.wav',
  JINGLE_WIN: 'jingle_win.wav',
  NAR_ALLIN: 'nar_allin.wav',
  NAR_CALL: 'nar_call.wav',
  NAR_CHECK: 'nar_check.wav',
  NAR_FOLD: 'nar_fold.wav',
  NAR_RAISE: 'nar_raise.wav',
  SFX_ALLIN: 'sfx_allin.wav',
  SFX_ALLIN_MERGE: 'sfx_allin_merge.wav',
  SFX_BET_BIG: 'sfx_bet_big.wav',
  SFX_BET_MIDDLE: 'sfx_bet_middle.wav',
  SFX_BET_SMALL: 'sfx_bet_small.wav',
  SFX_BUTTON1: 'sfx_button1.wav',
  SFX_BUTTON2: 'sfx_button2.wav',
  SFX_CARD_DIS: 'sfx_card_dis.wav',
  SFX_CARD_FLOP: 'sfx_card_flop.wav',
  SFX_CARD_HANDMADE: 'sfx_card_handmade.wav',
  SFX_CARD_PREMIUM: 'sfx_card_premium.wav',
  SFX_CARD_SQUASH: 'sfx_card_squash.wav',
  SFX_CHECK: 'sfx_check.wav',
  SFX_DEALER_BUTTON_MOVE: 'sfx_dealer_button_move.wav',
  SFX_GAME_IN: 'sfx_game_in.wav',
  SFX_GAME_OUT: 'sfx_game_out.wav',
  SFX_MYTURN: 'sfx_myturn.wav',
  SFX_POPUP_CLOSE: 'sfx_popup_close.wav',
  SFX_POPUP_OPEN: 'sfx_popup_open.wav',
  SFX_RAISE_GAGE: 'sfx_raise_gage.wav',
  SFX_RIVER_CARD_OPEN: 'sfx_river_card_open.wav',
  SFX_SHOWDOWN: 'sfx_showdown.wav',
  SFX_TIMER_ALERT_10: 'sfx_timer_alert_10.wav',
  SFX_TIMER_TICK_1: 'sfx_timer_tick_1.wav',
  SFX_TIMER_TICK_10: 'sfx_timer_tick_10.wav',
  SFX_TURN_CARD: 'sfx_turn_card.wav',
}

const sources: { [key: string]: AudioBuffer } = {}
const nodes: { [key: string]: AudioBufferSourceNode } = {};

const audioCtx = new AudioContext();

let tryLoading = false;
let loaded = false;

export async function initSounds() {
  while(tryLoading){
    await new Promise(r => setTimeout(r, 500));
  }

  if(loaded){
    return ;
  }

  tryLoading = true;
  const promises: Promise<void>[] = [];

  Object.values(Sounds).forEach((file) => {
    const url = `/sounds/${file}`;
    const promise = new Promise<void>(async (r) => {
      try {
        // const res = await fetch(url,{
        //   cache: 'force-cache',
        //   headers: {
        //     'Cache-Control': 'max-age=31536000',
        //     'Pragma': 'cache'
        //   }
        // });
        // const data = await res.arrayBuffer();

        let data = await new Promise(resolve => {
          var xhr = new XMLHttpRequest();
          xhr.onload = function(){
            resolve(xhr.response);
          }
          xhr.open('GET', url, true);
          xhr.setRequestHeader("Pragma", "cache");
          xhr.setRequestHeader("cache-control", "max-age=31536000");
          xhr.responseType='arraybuffer';
          xhr.send();
        });

        sources[file] = await audioCtx.decodeAudioData(data as any);
      } catch (e) {
        console.log('failed to load sound', url, e);
      }

      r();
    });

    promises.push(promise)
  });

  await Promise.allSettled(promises);
  loaded = true;
  tryLoading = false;
}

function getGameOptions() {
  let gameOption;

  try {
    gameOption = JSON.parse(localStorage.getItem(gameOptionState.key)!);
  } catch (e) {
  }

  if (!gameOption || typeof gameOption !== 'object') {
    gameOption = {};
  }

  if (!gameOption.hasOwnProperty('bgmMuted')) {
    gameOption.bgmMuted = false;
  }
  if (!gameOption.hasOwnProperty('sfxMuted')) {
    gameOption.sfxMuted = false;
  }

  return gameOption;
}

export function playBGM(sound: string) {
  const opt = getGameOptions();
  if (opt.bgmMuted) {
    return;
  }

  stopBGM();

  if (!isPlaying(sound)) {
    playSound(sound, opt.bgmVolume, false, true);
  }
}

export function playSFX(sound: string, force?: boolean) {
  const opt = getGameOptions();
  if (opt.sfxMuted) {
    return;
  }

  console.log('playSFX', sound, force);
  playSound(sound, opt.sfxVolume, Boolean(force), false);
}

function isPlaying(sound: string) {
  return nodes[sound] !== undefined;
}

function isBGM(sound: string) {
  return sound.includes('bgm_');
}

function playSound(sound: string, volume: number, force: boolean, loop: boolean = false) {
  const source = sources[sound];
  if (!source) {
    initSounds().then(()=>{
      playSound(sound, volume, force, loop)
    });
    return;
  }

  // 이미 재생중인 소스라면 재생하지 않는다. force 플래그가 true면 무시하고 재생한다.
  if (isPlaying(sound) && !force) {
    return;
  }

  // 만약 SFX 사운드면 suspended 상태에서는 재생 안함
  if (audioCtx.state !== 'suspended' || isBGM(sound)) {
    const sourceNode = new AudioBufferSourceNode(audioCtx, {
      buffer: source,
      loop: loop,
    });
    sourceNode.connect(audioCtx.destination);
    sourceNode.onended = () => {
      if (nodes[sound] === sourceNode) {
        delete nodes[sound];
      }
    }
    sourceNode.start();
    nodes[sound] = sourceNode;
  }

  if (audioCtx.state === 'suspended') {
    audioCtx.resume();
  }
}

export function stopSound(sound: string) {
  const node = nodes[sound];
  if (node) {
    node.stop();
    delete nodes[sound];
  }
}

export function stopAllSound() {
  for (let sound in nodes) {
    // BGM 사운드는 멈추지 않는다.
    if (isBGM(sound)) {
      continue;
    }

    stopSound(sound);
  }
}

export function stopBGM() {
  const sound = Object.keys(nodes).find(x => isBGM(x));
  if (sound) {
    stopSound(sound);
  }
}